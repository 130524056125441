import { useState, useEffect } from "react";

const EditProductForm = (props) => {
    console.log("current product", props.currentProduct.product_id);
    let product = props.currentProduct;
    const categories = props.categories;
    const [inputValue, setInputValue] = useState({
        productName: product.name,
        productPrice: product.price.whole + "." + product.price.fraction,
        productCompPrice: product.comp_price.whole + "." + product.comp_price.fraction,
        productCategory: product.category,
        productDescription: product.description,
        productImage: product.image,
        productMinQty: product.min_qty,
        productWeight: product.weight,
        productDimensions: product.dimensions,
    });
    const {
        productName,
        productPrice,
        productCompPrice,
        productCategory,
        productDescription,
        productImage,
        productMinQty,
        productWeight,
        productDimensions,
    } = inputValue;

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setInputValue({
            ...inputValue,
            // productCategory: category,
            [name]: value,
        });
        console.log(inputValue);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let productDetails = {
            name: productName,
            price: productPrice
                ? {
                      whole: parseInt(productPrice.split(".")[0]),
                      fraction: parseInt(productPrice.split(".")[1]),
                  }
                : {
                      whole: 1,
                      fraction: 50,
                  },
            comp_price: productCompPrice
                ? {
                      whole: parseInt(productCompPrice.split(".")[0]),
                      fraction: parseInt(productCompPrice.split(".")[1]),
                  }
                : {
                      whole: 1,
                      fraction: 50,
                  },
            category: productCategory,
            description: productDescription,
            image: productImage,
            min_qty: productMinQty,
            weight: productWeight ? productWeight : "1.0 grams",
            dimenensions: productDimensions ? productDimensions : "1.0 x 1.0 x 1.0 cm",
        };

        const options = {
            method: "PUT",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ update: productDetails }),
        };

        let res = await fetch(`${process.env.REACT_APP_API_URL}/products/${product.product_id}`, options);
        console.log(res.status);
        let data = await res.json();

        console.log("updated", data.name);
    };

    const alertConfirm = () => {
        props.setUpdated(true);
        alert("Product successfully updated!");
    };

    useEffect(() => {
        setInputValue({
            productName: product.name,
            productPrice: product.price.whole + "." + product.price.fraction,
            productCompPrice: product.comp_price.whole + "." + product.comp_price.fraction,
            productCategory: product.category,
            productDescription: product.description,
            productImage: product.image,
            productMinQty: product.min_qty,
            productWeight: product.weight,
            productDimensions: product.dimensions,
        });
    }, [product]);

    return (
        <div className="EditProductForm w-100 d-flex flex-column align-items-center">
            <div className="col-12 text-start ms-3">
                <div className="col-10 py-2 mb-4 mx-auto">
                    {/* <h1 className="display-4 mb-3">Input New Product Info</h1> */}
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="productName" className="form-label">
                                Name
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="productName"
                                value={productName}
                                onChange={handleOnChange}
                            />
                        </div>
                        <div className="mb-3 row">
                            <div className="col-3 text-center">
                                <label htmlFor="productPrice" className="form-label">
                                    Price
                                </label>
                                <div className="input-group">
                                    <span className="input-group-text fs-5">$</span>
                                    <input
                                        type="number"
                                        min="0.01"
                                        step="0.01"
                                        className="form-control fs-5"
                                        name="productPrice"
                                        value={productPrice}
                                        onChange={handleOnChange}
                                    />
                                </div>
                            </div>
                            <div className="col-4 text-center">
                                <label htmlFor="productCompPrice" className="form-label">
                                    Compared Price
                                </label>
                                <div className="input-group">
                                    <span className="input-group-text fs-5">$</span>
                                    <input
                                        type="number"
                                        min="0.01"
                                        step="0.01"
                                        className="form-control fs-5"
                                        name="productCompPrice"
                                        value={productCompPrice}
                                        onChange={handleOnChange}
                                    />
                                </div>
                            </div>
                            <div className="col-5 text-center">
                                <label htmlFor="productCategory" className="form-label">
                                    Category
                                </label>
                                {/* dropdown */}

                                <div className="dropdown">
                                    <button
                                        className="btn btn-outline-dark btn-lg dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {productCategory}
                                    </button>
                                    <ul className="dropdown-menu">
                                        {categories.map((c) => (
                                            // <li><p className="dropdown-item" onClick={() => setCategory(c.name)}>{c.name}</p></li>
                                            <li key={c.name}>
                                                <p
                                                    className="dropdown-item"
                                                    onClick={() =>
                                                        setInputValue({ ...inputValue, productCategory: c.name })
                                                    }
                                                >
                                                    {c.name}
                                                </p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                {/* <input
                                    type="text"
                                    className="form-control"
                                    name="productCategory"
                                    value={productCategory}
                                    onChange={handleOnChange}
                                /> */}
                                {/* dropdown */}
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="productDescription" className="form-label">
                                Description
                            </label>
                            <textarea
                                type="text"
                                className="form-control"
                                name="productDescription"
                                value={productDescription}
                                onChange={handleOnChange}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="productImage" className="form-label">
                                Image URL
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="productImage"
                                value={productImage}
                                onChange={handleOnChange}
                            />
                        </div>

                        <div className="mb-3 row">
                            <div className="col-6 text-center">
                                <label htmlFor="productMinQty" className="form-label">
                                    Min Qty for Order
                                </label>
                                <div className="input-group">
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="productMinQty"
                                        value={productMinQty}
                                        onChange={handleOnChange}
                                    />
                                    <span className="input-group-text fs-6">units</span>
                                </div>
                            </div>
                            <div className="col-6 text-center">
                                <label htmlFor="productWeight" className="form-label">
                                    Weight
                                </label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control fs-5"
                                        name="productWeight"
                                        value={productWeight}
                                        onChange={handleOnChange}
                                    />
                                    <span className="input-group-text fs-6">(include units)</span>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <div>
                                <label htmlFor="productDimensions" className="form-label">
                                    Dimensions
                                </label>
                            </div>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control fs-5"
                                    name="productDimensions"
                                    value={productDimensions}
                                    onChange={handleOnChange}
                                />
                                <span className="input-group-text fs-6">(include units)</span>
                            </div>

                            {/* <div className="row text-center">
                                <div className="col-4 form-label fs-6">Length</div>
                                <div className="col-4 form-label fs-6">Width</div>
                                <div className="col-4 form-label fs-6">Height</div>
                            </div>
                            <div className="row">
                                <div className="input-group input-group-sm col">
                                    <input
                                        type="number"
                                        min="0.01"
                                        step="0.01"
                                        className="form-control fs-5"
                                        name="productLength"
                                        value={productLength}
                                        onChange={handleOnChange}
                                    />
                                    <span className="input-group-text fs-6">in</span>
                                </div>
                                <div className="input-group input-group-sm col">
                                    <input
                                        type="number"
                                        min="0.01"
                                        step="0.01"
                                        className="form-control fs-5"
                                        name="productWidth"
                                        value={productWidth}
                                        onChange={handleOnChange}
                                    />
                                    <span className="input-group-text fs-6">in</span>
                                </div>
                                <div className="input-group input-group-sm col">
                                    <input
                                        type="number"
                                        min="0.01"
                                        step="0.01"
                                        className="form-control fs-5"
                                        name="productHeight"
                                        value={productHeight}
                                        onChange={handleOnChange}
                                    />
                                    <span className="input-group-text fs-6">in</span>
                                </div>
                            </div> */}
                        </div>

                        <button
                            type="submit"
                            className="btn btn-primary"
                            data-bs-dismiss="modal"
                            data-bs-target="#editProductModal"
                            aria-label="Close"
                            onClick={alertConfirm}
                        >
                            Update Product
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default EditProductForm;
