import { useState, useEffect } from "react";
import { formatPrice } from "../../../util/functions";
import AdminProductSearch from "./Carousel/AdminProductSearch";

function CarouselPanel() {
    const [products, setProducts] = useState([]);
    const [ids, setIds] = useState([]);

    const getProducts = async () => {
        let res = await fetch(`${process.env.REACT_APP_API_URL}/products`);
        let productData = await res.json();

        res = await fetch(`${process.env.REACT_APP_API_URL}/carousel`);
        let ids = await res.json();
        setIds(ids);

        setProducts(productData.filter((product) => ids.includes(product.product_id)));
    };

    const removeFromCarousel = async (id) => {
        if (ids.length <= 1) {
            alert("Carousel must have at least 1 product.");
            return;
        }
        setIds(ids.filter((i) => i !== id));
    }

    const saveCarousel = async () => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/carousel`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ ids }),
        });
        const data = await res.json();
        console.log(data);
        alert("Carousel saved.");
    };

    useEffect(() => {
        if (products.length > 0) {
            // setProducts(products.filter((product) => ids.includes(product.product_id)));
            return;
        }
        getProducts();
    }, [ids]);

    if (!products || products.length === 0) {
        return (
            <div className="AP_ProductList justify-content-center col-11 row">
                <div className="col-12">
                    <h1 className="display-4">Customize Carousel:</h1>
                </div>
            </div>
        );
    }

    return (
        <div className="AP_ProductList justify-content-center col-11 row">
            <div className="col-12">
                <h1 className="display-4">Customize Carousel</h1>
                <button className="btn btn-primary fs-3 mt-3" onClick={() => saveCarousel(ids)}>Save Carousel</button>
            </div>
            <div className="col-6">
                <h1 className="display-5">Current Carousel:</h1>
                <div
                    className="container col-8 my-2 mx-auto d-flex flex-column justify-content-center"
                    style={{ height: "3rem" }}
                >
                    <h3 className="lead fs-4 mb-0">Items in Carousel: {ids.length}/30</h3>
                </div>
                <div className="row">
                    {products.filter((product) => ids.includes(product.product_id)).map((p) => (
                        <div className="container col-10 my-2 mx-auto" key={p.product_id}>
                            <div className="ProductCardInner row border h-100">
                                <div
                                    className="d-flex justify-content-center align-items-center border p-3 bg-light"
                                    style={{ width: "18rem" }}
                                >
                                    <img
                                        src={p.image}
                                        className="img-fluid"
                                        alt="product"
                                        style={{ height: "15rem" }}
                                    />
                                </div>
                                <div className="col pt-1 ps-4 d-flex flex-column justify-content-center">
                                    <div
                                        className="d-flex flex-column align-items-start justify-content-center"
                                        style={{ height: "80%" }}
                                    >
                                        <h5
                                            className="product-name fw-bold lead fs-4 mb-3 text-start"
                                            style={{ fontSize: "1.2rem" }}
                                        >
                                            {p.name}
                                        </h5>
                                        <p className="card-price fs-6">
                                            <span className="fs-2 display-3">${p.price.whole}.</span>
                                            {formatPrice(p.price.fraction)}
                                        </p>
                                    </div>
                                    <button className="btn btn-danger ms-auto mw-25" onClick={() => removeFromCarousel(p.product_id)}>Remove</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="col-6">
                <h1 className="display-5">Add Products:</h1>
                <AdminProductSearch products={products} ids={ids} setIds={setIds}/>
            </div>
        </div>
    );
}

export default CarouselPanel;
