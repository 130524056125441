import Home from "./AP_Home";
import Orders from "./AP_Orders";
import Products from "./AP_Products";
import Carousel from "./AP_Carousel";
// import AddProducts from "./AP_AddProducts";
// import Settings from "./AP_Settings";
import Categories from "./AP_Categories";
import Users from "./AP_Users";


const AdminPanelTabs = (props) => {

    const tab = props.tab

    if (tab === "home"){
        return(<Home user={props.user}/>)
    }
    else if (tab === "orders"){
        return(<Orders />)
    }
    else if (tab === "products"){
        return(<Products />)
    }
    else if (tab === "carousel"){
        return(<Carousel />)
    }
    else if (tab === "products_new"){
        return(<Products />)
    }
    else if (tab === "categories"){
        return(<Categories />)
    }
    else if (tab === "users"){
        return(<Users />)
    }

    else {
        return (
            <>
                Error: No Tab Rendered
            </>
        );
    }

};

export default AdminPanelTabs;
