import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ProductCard from "./ProductCard";
import ProductCard2 from "./ProductCard2";

import { filterProducts } from '../util/search'

function ProductList() {
    const [products, setProducts] = useState([]);
    const [orders, setOrders] = useState([]);

    const location = useLocation();

    let search = decodeURIComponent(location.search.slice(8))
    // console.log("Search:", search)

    useEffect(() => {
        const getProducts = async () => {
            let res = await fetch(`${process.env.REACT_APP_API_URL}/products?limit=30`);
            let productData = await res.json();

            let filtered = filterProducts(productData, search);
            filtered.sort((a, b) => a.product_id - b.product_id)
            console.log("products", filtered)
            setProducts(filtered);
        };

        const getOrders = async () => {
            let res = await fetch(`${process.env.REACT_APP_API_URL}/orders?limit=30`);
            let ordersData = await res.json();
            ordersData.sort((a, b) => a.product_id - b.product_id)
            console.log("orders", ordersData)

            setOrders(ordersData);
        };

        getProducts();
        getOrders();
    }, [location, search]);

    if (products[0] === "none"){
        return (
            <div className="col-12">
                <h1 className="lead fs-3 mt-4 mb-4">No Results found for "<span className="lead fs-3" style={{fontWeight: 600}}>{search}</span>"</h1>
            </div>
        )
    }

    if ((!products || products.length === 0) || (!orders || orders.length === 0)) {
        <>
            <h1 className="lead fs-3 mt-4 mb-4 text-dark">Loading...</h1>
            <div class="fs-1 spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </>
    }

    return (
        <div className="ProductList justify-content-center mb-4 pt-4">
            {search ?
            <div className="mx-auto px-1 px-sm-5 text-start">
                <h1 className="lead fs-3 mt-2 mb-4 px-2 mx-1">Results for: "<span className="lead fs-3" style={{fontWeight: 600}}>{search}</span>"</h1>
            </div>
            : <></> }
            <div className="mx-auto d-flex flex-wrap justify-content-center px-1 px-sm-5">
                {/* {products.map((product) => (
                    <ProductCard key={product.product_id} productData={product} orders={orders} order={orders[products.indexOf(product)]}/>
                ))} */}
                {products.map((p) => (
                    <ProductCard2 key={p.product_id} product={p} orders={orders} order={orders[products.indexOf(p)]}/>
                ))}
            </div>
        </div>
    );
}

export default ProductList;
