import { Link } from "react-router-dom";
import CartUpdater from "../context/cartUpdater";
import OrderBar from "./OrderBar";
import { formatPrice } from "../util/functions";
import "../ProductCard2.css";

const ProductCard = ({ product, order }) => {
    let cents = formatPrice(product.price.fraction);

    if (product.product_id === undefined) {
        return <></>;
    }

    return (
        <div className="ProductCard col-2 text-black mx-2 px-1 mt-2 mb-5">
            <div className="card-upper">
                <div className="img-container">
                    <img className="img-fluid card-img p-2 pb-0" src={product.image} alt="" />
                </div>
            </div>
            <div className="card-lower d-flex flex-column justify-content-evenly px-0 mx-auto">
                <div className="card-text-container mb-1 mt-1">
                    <p className="card-name mb-0">{product.name}</p>
                    <div>
                    <div className="card-price fw-bold fs-4 mb-0">
                        ${product.price.whole}.{cents} <span className="text-secondary card-moq fw-light">/ piece</span>
                    </div>
                        {/* <OrderBar order={order} /> */}
                        <div className="d-flex mt-3">
                            <p className="card-moq">
                                {product.min_qty} pieces <span className="text-secondary">(MOQ)</span>
                            </p>
                            <CartUpdater
                                item={{ product: product, qty: 1, image: product.image }}
                                classes={"btn-outline-info"}
                                styling={{
                                    // marginTop: "2.5%",
                                    // marginBottom: "5%",
                                    marginLeft: "auto",
                                    marginRight: 0,
                                    width: "50%",
                                    // fontSize: "1.1rem",
                                    // padding: "0.35rem 1rem",
                                    borderRadius: "1rem",
                                    borderColor: "rgb(37, 165, 255)",
                                    borderWidth: "1.5px",
                                    color: "black",
                                    "--bs-btn-outline-color": "rgba(47, 180, 255)",
                                    // '--bs-btn-bg': "rgba(47, 180, 255)",
                                    "--bs-btn-border-color": "rgba(47, 180, 255)",
                                    "--bs-btn-hover-color": "#000",
                                    "--bs-btn-hover-bg": "rgba(49, 180, 255)",
                                    "--bs-btn-hover-border-color": "rgba(70, 200, 255)",
                                    "--bs-btn-focus-shadow-rgb": "11, 172, 204",
                                    // '--bs-btn-active-color': "rgba(70, 215, 255)",
                                    "--bs-btn-active-bg": "rgba(47, 180, 255)",
                                    // boxShadow: "rgba(0, 0, 0, 0.16) 0px 0.75px 1.5px, rgba(0, 0, 0, 0.23) 0px 0.75px 1.5px"
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
