import { useState, useEffect } from "react";

import Marquee from "react-fast-marquee";
import CarouselCard from "../CarouselCard";

let HomeCarousel = (props) => {
    const [ids, setIds] = useState([]);
    const [products, setProducts] = useState([]);
    const [start, end] = props.slc; // slc === slice for how many products to show

    console.log(props)


    useEffect(() => {
        if (products.length > 0) return;
        const fetchCarouselData = async () => {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/carousel`);
            const data = await res.json();
            setIds(data);
            setProducts(props.products.filter((product) => data.includes(product.product_id)));
        };
        fetchCarouselData();
    }, [ids, products]);

    return (
        <div className="HomeCarousel mx-auto px-5 mb-1 my-0">
            <Marquee gradient="true" gradientWidth={50} direction={props.dir}>
                {products.slice(start, end).map((p) => (
                    <CarouselCard key={p.product_id} productData={p} />
                ))}
            </Marquee>
        </div>
    );
};

export default HomeCarousel;
