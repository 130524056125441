import { useState, useEffect } from "react";
import { formatPrice } from "../../../../util/functions";

const AdminProductSearch = ({ products, ids, setIds }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredProducts, setFilteredProducts] = useState(products);

    const addToCarousel = async (id) => {
        if (ids.length >= 30) {
            alert("Carousel can only have 30 products.");
            return;
        }
        if (ids.includes(id)) {
            alert("Product already in carousel.");
            return;
        }
        setIds([...ids, id]);
    }

    useEffect(() => {
        if (!products) return;
        const results = products.filter((product) => product.name.toLowerCase().includes(searchTerm.toLowerCase()));
        setFilteredProducts(results);
    }, [searchTerm, products]);

    return (
        <div className="AdminProductSearch">
            <div className="container col-8 my-2 mx-auto" style={{ height: "3rem" }}>
                <input
                    className="form-control"
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <div className="row">
                {filteredProducts.map((p) => (
                    <div className="container col-10 my-2 mx-auto" key={p.product_id}>
                        <div className="ProductCardInner row border h-100">
                            <div className="col pt-1 ps-4 d-flex flex-column justify-content-center">
                                <div
                                    className="d-flex flex-column align-items-start justify-content-center"
                                    style={{ height: "80%" }}
                                >
                                    <h5
                                        className="product-name fw-bold lead fs-4 mb-3 text-start"
                                        style={{ fontSize: "1.2rem" }}
                                    >
                                        {p.name}
                                    </h5>
                                    <p className="card-price fs-6">
                                        <span className="fs-2 display-3">${p.price.whole}.</span>
                                        {formatPrice(p.price.fraction)}
                                    </p>
                                </div>
                                <button className="btn btn-success me-auto mw-25" onClick={() => addToCarousel(p.product_id)}>Add</button>
                            </div>
                            <div
                                className="d-flex justify-content-center align-items-center border p-3 bg-light"
                                style={{ width: "18rem" }}
                            >
                                <img src={p.image} className="img-fluid" alt="product" style={{ height: "15rem" }} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdminProductSearch;
